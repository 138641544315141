
	frappe.templates['form_sidebar'] = `<div class="sidebar-section user-actions hidden"></div>
<div class="sidebar-section sidebar-image-section hide">
	<div class="sidebar-image-wrapper">
		<img class="sidebar-image">
		<div class="sidebar-standard-image">
			<div class="standard-image"></div>
		</div>
		{% if can_write %}
		<div class="sidebar-image-actions">
			<div class="dropdown">
				<a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ __("Change") }}</a>
				<div class="dropdown-menu" role="menu">
					<a class="dropdown-item sidebar-image-change">{{ __("Upload") }}</a>
					<a class="dropdown-item sidebar-image-remove">{{ __("Remove") }}</a>
				</div>
			</div>
		</div>
		{% endif %}
	</div>
</div>
{% if frm.meta.beta %}
<div class="sidebar-section">
	<p><label class="indicator-pill yellow" title="{{ __("This feature is brand new and still experimental") }}">{{ __("Experimental") }}</label></p>
	<div><a class="small text-muted" href="https://github.com/frappe/{{ frappe.boot.module_app[frappe.scrub(frm.meta.module)] }}/issues/new"
			target="_blank">
		{{ __("Report bug") }}</a></div>

</div>
{% endif %}
<div class="sidebar-section sidebar-rating hide">
	<div style="position: relative;">
		<a class="strong badge-hover">
			<span>{%= __("Feedback") %}</span>
		</a>
	</div>
	<div class="rating-icons"></div>
</div>
<div class="sidebar-section hidden">
	{% if (frappe.help.has_help(doctype)) { %}
	<div>
		<a class="help-link list-link" data-doctype="{{ doctype }}">{{ __("Help") }}</a>
	</div>
	{% } %}
</div>
<div class="sidebar-section form-assignments">
	<div>
		<span class="form-sidebar-items">
			<span class="add-assignment-label">
				<svg class="es-icon ml-0 icon-sm"><use href="#es-line-add-people"></use></svg>
				<span class="ellipsis">{%= __("Assigned To") %}</span>
			</span>
			<button class="add-assignment-btn btn btn-link icon-btn">
				<svg class="es-icon icon-sm"><use href="#es-line-add"></use></svg>
			</button>
		</span>
		<div class="assignments"></div>
	</div>
</div>
<div class="sidebar-section form-attachments">
	<div class="attachments-actions">
		<span class="form-sidebar-items">
			<span>
				<svg class="es-icon ml-0 icon-sm">
					<use href="#es-line-attachment"></use>
				</svg>
				<a class="pill-label ellipsis explore-link">
					{%= __("Attachments") %}
				</a>
			</span>
			<button class="add-attachment-btn btn btn-link icon-btn">
				<svg class="es-icon icon-sm"><use href="#es-line-add"></use></svg>
			</button>
		</span>
	</div>
	<a class="show-all-btn hidden" href="">
		<svg class="es-icon icon-sm">
			<use href="#es-line-preview"></use>
		</svg>
		<span class="pill-label ellipsis">
			{%= __("Show All") %}
		</span>
	</a>
</div>
<div class="sidebar-section form-reviews">
	<div class="reviews">
		<span class="form-sidebar-items">
			<span>
				<svg class="es-icon ml-0 icon-sm"><use href="#es-line-star"></use></svg>
				<span class="ellipsis">{%= __("Reviews") %}</span>
			</span>
			<button class="add-review-btn btn btn-link icon-btn">
				<svg class="es-icon icon-sm"><use href="#es-line-add"></use></svg>
			</button>
		</span>
	</div>
</div>
<div class="sidebar-section form-tags">
	<div>
		<span class="form-sidebar-items">
			<div>
				<svg class="es-icon ml-0 icon-sm"><use href="#es-line-tag"></use></svg>
				<span class="tags-label ellipsis">{%= __("Tags") %}</span>
			</div>
		</span>
	</div>
</div>
<div class="sidebar-section form-shared">
	<div>
		<span class="form-sidebar-items">
			<span class="share-label">
				<svg class="es-icon ml-0 icon-sm"><use href="#es-line-add-people"></use></svg>
				<span class="ellipsis">{%= __("Share") %}</span>
			</span>
			<button class="share-doc-btn btn btn-link icon-btn">
				<svg class="es-icon icon-sm"><use href="#es-line-add"></use></svg>
			</button>
		</span>
		<div class="shares"></div>
	</div>
</div>
<div class="sidebar-section followed-by-section hidden">
	<div class="sidebar-label followed-by-label">
		<svg class="icon icon-sm">
			<use href="#icon-link-url" class="like-icon"></use>
		</svg>
		{%= __("Followed by") %}
	</div>
	<div class="followed-by"></div>
	<div class="document-follow">
		<a class="badge-hover follow-document-link hidden">
			{%= __("Follow") %}
		</a>
		<a class="badge-hover unfollow-document-link hidden">
			{%= __("Unfollow") %}
		</a>
	</div>
</div>
<div class="sidebar-section hidden">
	<a><li class="indicator red blink error-log-status" style="display: none;">{%= __("Error Logs") %}</li></a>
	<a><li class="indicator yellow webhook-request-log-status" style="display: none;">{%= __("Webhook Logs") %}</li></a>
	<a><li class="indicator blue auto-repeat-status" style="display: none;"></li></a>
</div>
<div class="sidebar-section text-muted">
	<div class="pageview-count hidden"></div>
	<div class="created-modified-section">
	</div>
</div>
{% if(frappe.get_form_sidebar_extension) { %}
	{{ frappe.get_form_sidebar_extension() }}
{% } %}
`;
